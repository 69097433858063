import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AnimalCard from './AnimalCard';
import StatisticsGraph from './StatisticsGraph';

// Determine the base URL based on the environment
const baseURL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:5005' : '';



  const Dashboard = () => {
  const [animals, setAnimals] = useState([]);
  const [statistics, setStatistics] = useState([]);

  const [dateStr, setDateStr] = useState(new Date().toLocaleDateString());

  useEffect(() => {
    const interval = setInterval(() => {
      setDateStr(new Date().toLocaleDateString());
    }, 60000);
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    getAnimalStatus();
    getStatistics();
  }, []);

  // Update the animal status every 1 minute
  useEffect(() => {
    const interval = setInterval(() => {
      getAnimalStatus();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // Update the statistics every 30 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      getStatistics();
    }, 1800000);
    return () => clearInterval(interval);
  }, []);

  const getAnimalStatus = () => {
    axios.get(`${baseURL}/api/animals/status`).then((response) => {
      setAnimals(response.data);
    });
  };

  const getStatistics = () => {
    axios.get(`${baseURL}/api/animals/statistics`).then((response) => {
      setStatistics(response.data);
    });
  };

  // Update the status of an animal every time the toggle button is clicked
  const handleToggle = (id) => {
    axios.post(`${baseURL}/api/animals/toggle/${id}`).then(() => {
      // Refresh the animal status after toggling
      getAnimalStatus();
    });
  };

  return (
    <div className="container mx-auto px-4">
      <p className="text-center text-xl mt-2 mb-10"> date: {dateStr}</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
        {animals.map((animal) => (
          <AnimalCard key={animal.id} animal={animal} onToggle={handleToggle} />
        ))}
      </div>
      <div className="mt-10">
        <StatisticsGraph data={statistics} />
      </div>
    </div>
  );
};

export default Dashboard;