import React from 'react';
import {
  HomeIcon,
  SunIcon,
  ArrowCircleRightIcon,
  ClockIcon,
} from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

const AnimalCard = ({ animal, onToggle }) => {
  const maxSecondsInADay = 24 * 3600; // Max seconds in a day
  const outsideTimeInSeconds = animal.outside_time_today; // Time outside in seconds
  const outsideHours = Math.floor(outsideTimeInSeconds / 3600); // Get hours
  const outsideMinutes = Math.floor((outsideTimeInSeconds % 3600) / 60); // Get remaining minutes
  const percentageOutside = (outsideTimeInSeconds / maxSecondsInADay) * 100;

  return (
    <div className="card w-full bg-base-100 shadow-xl rounded-xl">
      {/* Wrap the image in a Link to navigate to the animal details page */}
      <Link to={`/animal/${animal.id}`}>
        <figure className="max-h-56 rounded-t-xl overflow-hidden">
          <img
            src={animal.avatar}
            alt={animal.name}
            className="w-full h-full object-cover"
          />
        </figure>
      </Link>
      <div className="card-body">
        <h2 className="card-title">{animal.name}</h2>

        {/* Align items horizontally */}
        <div className="flex items-center gap-4">
          {/* Status */}
          <div className="flex items-center gap-2">
            {animal.status === 'inside' ? (
              <HomeIcon className="h-6 w-6 text-blue-500" />
            ) : (
              <SunIcon className="h-6 w-6 text-yellow-500" />
            )}
            <p>{animal.status === 'inside' ? 'Inside' : 'Outside'}</p>
          </div>
          {/* Outside Count */}
          <div className="flex items-center gap-2">
            <ArrowCircleRightIcon className="h-6 w-6 text-red-700" />
            <p>{animal.outside_count_today}</p>
          </div>
          {/* Time Outside */}
          <div className="flex items-center gap-2">
            <ClockIcon className="h-6 w-6 text-green-600" />
            <p>
              {outsideHours}:{outsideMinutes.toString().padStart(2, '0')}
            </p>
          </div>
        </div>

        {/* Progress Bar */}
        <div className="w-full bg-gray-200 rounded-full h-4 overflow-hidden mt-4">
          <div
            className="bg-green-500 h-4 rounded-full"
            style={{ width: `${percentageOutside}%` }}
          ></div>
        </div>

        {/* Toggle Button */}
        <button
          onClick={() => onToggle(animal.id)}
          className="btn btn-primary mt-4"
        >
          Toggle Inside/Outside
        </button>
      </div>
    </div>
  );
};

export default AnimalCard;