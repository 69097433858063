import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import Dashboard from './components/Dashboard';
import AnimalDetails from './components/AnimalDetails'; 

function App() {

  return (
    <Router>
      <div className="container mx-auto">
        <h1 className="text-center text-4xl mt-4">Animal Tracking Dashboard</h1>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/animal/:id" element={<AnimalDetails />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;