import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const StatisticsGraph = ({ data }) => {
  const chartData = {
    labels: data.map((stat) => stat.name),
    datasets: [
      {
        label: 'Time Outside (hours)',
        data: data.map((stat) => stat.total_time / 3600), // Convert time to hours
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,  // Disable aspect ratio to allow custom height
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Time Spent Outside by Animal',
      },
    },
  };

  return (
    <div className="card w-full bg-base-100 shadow-xl h-96 p-10">
    {/* <div className="w-full h-96">  Set custom height using Tailwind */}
    <h2 className="text-center text-2xl">Group Statistics</h2>

      <Line data={chartData} options={options} height={150} /> {/* Set height prop */}
    </div>
  );
};

export default StatisticsGraph;